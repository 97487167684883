import React, { useEffect, useCallback } from "react";
import ReactModal, { Styles } from "react-modal";

import { theme, styled, ColorDotWeight } from "@styles/theme";
import { breakpoints, ResponsiveSpacing } from "@styles/responsive";

import { Box, Flex } from "./ResponsiveBox";
import Button, { Colors } from "./Button";
import { H2 } from "./Headings";
import { Spacer } from "./Spacer";
import { Form } from "./Input";
import { Text } from "./Text";

import Icon from "../../icons/Icon";

const modalCustomStyles = {
  top: "50%",
  left: "50%",
  right: "auto" as const,
  bottom: "auto" as const,
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "none" as const,
  borderRadius: theme.radius,
  width: "90%",
  maxWidth: 588,
  padding: 0,
  maxHeight: "90%",
  overflow: "initial" as const,
  textAlign: "center" as const,
};

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;

  @media (max-width: ${breakpoints[0]}px) {
    top: 12px;
    right: 12px;
  }

  :focus {
    box-shadow: ${(p) => p.theme.shadow.focus};
  }
`;

type ActionFn = () => void;

type Action = {
  disabled?: boolean;
  color?: Colors;
  loading?: boolean;
  onPress?: ActionFn;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  small?: boolean;
  form?: string;
};

export interface Props {
  title?: string;
  subtitle?: string;
  form?: {
    errors?: string[];
    onSubmit: React.FormEventHandler<HTMLFormElement>;
  };
  style?: React.CSSProperties;
  cancelLabel?: string;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onRequestClose?: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
  onClickCancel?: () => void;
  shouldReturnFocusAfterClose?: boolean;
  isOpen?: boolean;
  hasCancel?: boolean;
  actions?: {
    [label: string]: Action | ActionFn | null;
  };
  closeIconColor?: ColorDotWeight;
  shouldCloseOnOverlayClick?: boolean;
  bodyPb?: ResponsiveSpacing;
}

export const Modal: React.FC<Props> = ({
  children,
  title,
  subtitle,
  style = {},
  onRequestClose,
  onClickCancel,
  onAfterOpen,
  onAfterClose,
  isOpen,
  actions,
  shouldReturnFocusAfterClose = true,
  hasCancel = true,
  cancelLabel = "Cancel",
  form,
  closeIconColor,
  shouldCloseOnOverlayClick = true,
  bodyPb,
}) => {
  useEffect(() => {
    ReactModal.setAppElement("#__next");
  }, []);

  const reactModalStyles: Styles = {
    content: {
      ...modalCustomStyles,
      ...style,
    },
    overlay: {
      zIndex: 9999,
    },
  };

  const modalContent = useCallback(
    (): JSX.Element => (
      <>
        <Box
          position="static"
          pX={["md", "xhg"]}
          pB={bodyPb}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Box>

        {(actions || hasCancel) && (
          <Flex
            justify={
              actions && Object.entries(actions)?.length === 1 && !hasCancel
                ? "center"
                : "flex-start"
            }
            alignItems="center"
            direction="row-reverse"
            bg="neutral.100"
            pX={["md", "xhg"]}
            pY="md"
            mT="xg"
            style={{
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {actions &&
              Object.entries(actions).map(([label, options]) => {
                const button =
                  typeof options === "function"
                    ? {
                        type: "button" as const,
                        disabled: false,
                        color: "blue",
                        onPress: options,
                        small: false,
                      }
                    : options;

                const color = (button?.color || "blue") as Colors;

                return (
                  <React.Fragment key={label}>
                    <Button {...button} color={color}>
                      {label}
                    </Button>
                    <Spacer size="xg" horizontal />
                  </React.Fragment>
                );
              })}
            {hasCancel && (
              <Button onPress={onClickCancel} link small>
                {cancelLabel}
              </Button>
            )}
          </Flex>
        )}
      </>
    ),
    [actions, onRequestClose, cancelLabel, children],
  );

  return (
    <ReactModal
      isOpen={isOpen || false}
      style={reactModalStyles}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      // TODO: updating react fix this, but create other issues
      // @ts-ignore
      onRequestClose={onRequestClose}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      ariaHideApp={process.env.NODE_ENV !== "test"}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {title ? (
        <Box
          pX={["md", "none"]}
          mB={subtitle ? "lg" : "none"}
          onClick={(e) => e.stopPropagation()}
        >
          <H2 mT="xg" mB={subtitle ? "xs" : "lg"}>
            {title}
          </H2>
          {subtitle && (
            <Box width="100%" pX={["none", "xg", "xhg"]}>
              <Text paragraph colorWeight="200" align="center">
                {subtitle}
              </Text>
            </Box>
          )}
        </Box>
      ) : (
        <Spacer size="xxg" />
      )}
      <CloseButton onClick={(e) => (e.stopPropagation(), onRequestClose?.(e))}>
        <Icon name="close" fill={closeIconColor ?? "neutral.500"} size={24} />
      </CloseButton>
      {form ? (
        <Form onSubmit={form.onSubmit} errors={form.errors}>
          {modalContent()}
        </Form>
      ) : (
        modalContent()
      )}
    </ReactModal>
  );
};
